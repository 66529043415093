import { useSelector } from "react-redux";

import { useSubStates } from "./subStateQueries";

export default function useCurrentRegionalTerritorySubStates() {
  const territory = useSelector((state: any) =>
    state.territories.territoryList.find((t) => t.id === state.app.territory)
  );

  const isRegional = !!territory && territory?.type === "Regional";

  const { data = [] } = useSubStates(
    { territoryId: territory?.id },
    { enabled: isRegional }
  );

  return {
    data,
    subStateIds: data?.map((obj) => obj.id) ?? [],
    isRegional,
  };
}
