/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import _, { filter } from "lodash";
import { formatUtcDate } from "src/utility/utilityFunctions";

import { Button, FaIcon } from "@features/ui";
import { Item, OrderSetVariant } from "@models";
import { OrderSetType } from "@models/OrderSet";

import useGetVariantAvailableInventory from "./hooks/useGetVariantAvailableInventory";

const getLabelText = (
  item: Item,
  orderSetVariants: OrderSetVariant[],
  totalInventory?: false | number
) => {
  const orderSetVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  if (filter(item.variants, "isActive").length === 0) return "Inactive";

  const defaultVariant = item.variants?.find(
    (v) => v.selectedVariantOptions.length === 0
  );

  const allVariantsInCart =
    defaultVariant && defaultVariant.isActive
      ? orderSetVariantIds.includes(defaultVariant.id)
      : item.variants?.every(
          (v) =>
            !v.isActive ||
            v.selectedVariantOptions.length === 0 ||
            orderSetVariantIds.includes(v.id)
        );

  if (allVariantsInCart) {
    return "In Cart"; // non-breaking space
  }
  if (typeof totalInventory === "number" && totalInventory <= 0) {
    if (item.poInMarketDate) {
      return `Out of Stock, Avail. ${formatUtcDate(
        item.poInMarketDate,
        "MMM d"
      )}`;
    }
    return "Out of Stock";
  }
  return "";
};

const AddToCartButton = ({
  item,
  orderSetVariants,
  addToOrderSet,
  orderType,
  loading: externalLoading,
}: {
  item: Item;
  orderSetVariants: OrderSetVariant[];
  addToOrderSet: (item: Item) => Promise<void>;
  orderType: OrderSetType;
  loading?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const getVariantQty = useGetVariantAvailableInventory();
  const totalInventory =
    orderType === "from-inventory" &&
    _.sumBy(item.variants, (v) => getVariantQty(v));

  const [message, setMessage] = useState(
    getLabelText(item, orderSetVariants, totalInventory)
  );

  const handleAddToCart = async () => {
    setLoading(true);
    await addToOrderSet(item);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    setMessage(getLabelText(item, orderSetVariants, totalInventory));
  }, [loading, item, orderSetVariants, totalInventory]);

  return (
    <Button
      disableElevation
      disableRipple
      loading={externalLoading || loading}
      disabled={!!message}
      onClick={handleAddToCart}
      tw="whitespace-nowrap text-left"
      endIcon={
        // mr-1 makes the transition between the icon and the spinner smoother, avoids a layout shift
        !message ? <FaIcon icon="plus" tw="text-base! mr-1" /> : undefined
      }
    >
      {message || "Add to order"}
    </Button>
  );
};

export default AddToCartButton;
