/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useActiveOrderWindows } from "@features/orderCalendarMonths";
import { useOnDemandNationalCouponPrograms } from "@features/programs";
import { Dashboard, Type } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

import { SECONDARY_ORDER_WINDOWS } from "../../constants/permissions";

const planningToolRoles = ["purchaser", "select-purchaser", "super", "field2"];

const FieldDash = () => {
  const activeOrderWindows = useActiveOrderWindows();
  const onDemandNationalCouponPrograms = useOnDemandNationalCouponPrograms();

  const hasActiveNational = activeOrderWindows.some(
    (ocm) => ocm.orderWindowType === "national"
  );
  const activeSecondary = activeOrderWindows.find(
    (ocm) => ocm.orderWindowType === "secondary"
  );
  const roleIs = useRoleIs();
  const cards = [
    {
      titleText: "RTA Planning Tool",
      link: "/planning",
      info: "View Planning Tool",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      icon: "map",
    },

    {
      titleText: "Order History",
      link: "/orders/history/item",
      info: "View order history grouped by item or order & get tracking information",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Order_History_jvvgmh.png",
      icon: "clock",
    },
    {
      titleText: "Help and Training Guides",
      link: "https://help.readytoactivate.com/",
      info: "View Help and Training Guides",
      icon: "circle-info",
    },
    {
      titleText: "Order Window Summary Report",
      link: "/reports/order-window-summary-presentation",
      info: "View order window summary report",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
      icon: "rectangle-history",
    },
    {
      titleText: "Item Catalog: Current",
      link: "/items",
      info: "View catalog of and details about currently available items",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
      icon: "box",
    },
    {
      titleText: "Item Catalog: Archive",
      link: "/items/archive",
      info: "View archive of and details about items no longer available",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Archive_wgetjy.png",
      icon: "box-archive",
    },
    {
      titleText: "Reports",
      link: "/reports",
      info: "View all reports",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
      icon: "chart-bar",
    },
    {
      titleText: "Item Rules",
      link: "/compliance/items",
      info: "View details about regional item compliance",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
      icon: "shield-check",
    },
    {
      titleText: "Budgets",
      link: "/budgets",
      info: "View budgets",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
      icon: "magnifying-glass-dollar",
    },
  ];

  return (
    <div tw="space-y-8">
      <div>
        <Type.H2 tw="mb-2">Place your orders</Type.H2>
        <Dashboard>
          {(roleIs(planningToolRoles) || hasActiveNational) && (
            <Dashboard.Card
              title="Monthly Pre-Order"
              secondary={
                hasActiveNational
                  ? "Place your Monthly Pre-Order."
                  : "There are currently no active Pre-Order programs"
              }
              link="/programs/national"
              icon="calendar-range"
              color="amber"
            />
          )}
          {activeSecondary && roleIs(SECONDARY_ORDER_WINDOWS) && (
            <Dashboard.Card
              title={activeSecondary.orderWindowName}
              secondary="Special Order Window"
              link="/programs/secondary"
              icon="calendar-star"
              color="purple"
            />
          )}
          {onDemandNationalCouponPrograms.length > 0 && (
            <Dashboard.Card
              title="National Coupons"
              secondary="Order coupons from these special programs"
              link="/orders/items/on-demand#national-coupons"
              icon="tickets"
              color="fuchsia"
            />
          )}
          <Dashboard.Card
            title="Made to Order (On-Demand)"
            secondary="Place orders for items that will need to be produced for your order"
            link="/orders/items/on-demand"
            icon="truck-clock"
            color="teal"
          />
          <Dashboard.Card
            title="Ready to Ship (Inventory)"
            secondary="Place orders for items that are currently available in our inventory"
            link="/orders/items/inventory"
            icon="truck-fast"
            color="sky"
          />
        </Dashboard>
      </div>
      <div>
        <Type.H2 tw="mb-2">Quick Links</Type.H2>
        <Dashboard>
          {cards.map(
            (data, index) =>
              (index > 0 || roleIs(planningToolRoles)) && (
                <Dashboard.Card
                  key={index}
                  title={data.titleText}
                  secondary={data.info}
                  link={data.link}
                  icon={data.icon}
                  color="gray"
                />
              )
          )}
        </Dashboard>
      </div>
    </div>
  );
};

export default FieldDash;
