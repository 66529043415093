/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { Skeleton } from "@mui/material";

import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { Dashboard, Type } from "@features/ui";
import client from "@services/api";
import useRoleIs from "@utils/useRoleIs";

import { PLANNING_TOOL_SUPPLIER } from "../../constants/permissions";

const DataCount = ({ resource, filter }) => {
  const { data } = useQuery({
    queryKey: [resource, filter],
    queryFn: () => client.get(resource, { params: { filter } }),
    staleTime: 5 * 60 * 1000,
    placeholderData: keepPreviousData,
  });

  if (!data) return <Skeleton variant="text" width={50} height={24} />;
  return data.meta.total_entries;
};

const SupplierDash = () => {
  const roleIs = useRoleIs();

  const cardData = [
    roleIs(PLANNING_TOOL_SUPPLIER) && {
      titleText: "RTA Planning Tool",
      link: "/planning/dashboard",
      info: "View Planning Tool",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
    },
    {
      titleText: "Bulk PO Shipping Report",
      link: "/reports/po-ship-list",
      info: "View and upload shipping parameters from multiple purchase orders.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
    },
  ].filter(Boolean);
  return (
    <div tw="space-y-6">
      <div>
        <Type.H2 tw="mb-2 hover:underline">
          <Link to="/purchasing/purchase-orders">Purchase Orders</Link>
        </Type.H2>
        <Dashboard>
          <Dashboard.Card
            title="New"
            secondary={
              <DataCount
                resource="purchase-orders"
                filter={{ status: "submitted" }}
              />
            }
            link="/purchasing/purchase-orders?purchaseOrderStatus=submitted"
          />
          <Dashboard.Card
            title="In Progress"
            secondary={
              <DataCount
                resource="purchase-orders"
                filter={{ status: "in-progress" }}
              />
            }
            link="/purchasing/purchase-orders?purchaseOrderStatus=in-progress"
          />
          <Dashboard.Card
            title="Shipping Hold"
            secondary={
              <DataCount
                resource="purchase-orders"
                filter={{ status: "in-progress", hasShipHold: true }}
              />
            }
            link="/purchasing/purchase-orders?purchaseOrderStatus=shipping-hold"
          />
        </Dashboard>
      </div>
      <div>
        <Type.H2 tw="mb-2 hover:underline">
          <Link to="/purchasing/rfp-history">Price Requests</Link>
        </Type.H2>
        <Dashboard>
          <Dashboard.Card
            title="New"
            secondary={
              <DataCount
                resource="request-for-prices"
                filter={{ bidStatus: "sent" }}
              />
            }
            link="/purchasing/rfp-history?rfpStatus=sent"
          />
          <Dashboard.Card
            title="In Progress"
            secondary={
              <DataCount
                resource="request-for-prices"
                filter={{ bidStatus: "accepted" }}
              />
            }
            link="/purchasing/rfp-history?rfpStatus=accepted"
          />
          <Dashboard.Card
            title="Awarded"
            secondary={
              <DataCount
                resource="request-for-prices"
                filter={{ bidStatus: "awarded" }}
              />
            }
            link="/purchasing/rfp-history?rfpStatus=awarded"
          />
        </Dashboard>
      </div>

      <div>
        <Type.H2 tw="mb-2">
          <Link to="/quotes">Quote Requests</Link>
        </Type.H2>
        <Dashboard>
          <Dashboard.Card
            title="New"
            secondary={
              <DataCount resource="quotes" filter={{ status: "sent" }} />
            }
            link="/quotes?quoteStatus=sent"
          />
          <Dashboard.Card
            title="In Progress"
            secondary={
              <DataCount resource="quotes" filter={{ status: "accepted" }} />
            }
            link="/quotes?quoteStatus=accepted"
          />
          <Dashboard.Card
            title="Complete"
            secondary={
              <DataCount resource="quotes" filter={{ status: "complete" }} />
            }
            link="/quotes?quoteStatus=complete"
          />
        </Dashboard>
      </div>

      <div>
        <Type.H2 tw="mb-2 hover:underline">Quick Links</Type.H2>
        <Dashboard>
          {cardData.map((data, index) => (
            <Dashboard.Card
              key={index}
              title={data.titleText}
              secondary={data.info}
              link={data.link}
            />
          ))}
        </Dashboard>
      </div>
    </div>
  );
};
export default SupplierDash;
