import { useDispatch, useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NON_SUPPLIER_ROLES } from "src/constants/permissions";
import { setDraftOrderId } from "src/redux/slices/appSlice";

import { useAuthUser } from "@features/auth";
import { Order } from "@models/Order";
import { OrderSet, OrderSetType } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";
import { buildPaginatedQuery } from "@utils/reactQuery";
import { Paginated, QueryOptions } from "@utils/reactQuery/types";
import useRoleIs from "@utils/useRoleIs";

import usePreOrderPrograms from "../preOrder/usePreOrderPrograms";

export const orderSetsKeyFactory = createQueryKeys("order-sets", {
  detail: (orderSetId) => ({
    queryKey: [orderSetId],
    queryFn: () =>
      client.get<OrderSet>(`order-sets/${orderSetId}`).then((res) => res!.data),
    contextQueries: {
      orders: {
        queryKey: null, // ['order-sets', 'detail', orderSetId, 'orders']
        queryFn: () =>
          client
            .get<Order[]>("orders", {
              params: { filter: { orderSetId }, skipPagination: true },
            })
            .then((res) => res.data),
      },
    },
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<OrderSet[]>("order-sets", { params }),
  }),
});

export const useOrderSetQuery = (
  orderSetId?: string | null,
  options?: QueryOptions<OrderSet>
) => {
  return useQuery({
    ...orderSetsKeyFactory.detail(orderSetId),
    enabled: !!orderSetId,
    ...options,
  });
};

export const useOrderSetsPaginated = buildPaginatedQuery(
  orderSetsKeyFactory.paginated
);

export const useDraftOrderSetsQuery = () => {
  const { id } = useAuthUser();
  const roleIs = useRoleIs();
  return useOrderSetsPaginated(
    {
      filter: {
        userIds: [id],
        status: ["in-progress", "inactive"],
        type: "not-pre-order",
      },
      page: { size: 100 },
    },
    {
      enabled: roleIs(NON_SUPPLIER_ROLES as any),
    }
  );
};

export const useDraftPreOrderOrderSetQuery = (
  programId: string,
  orderWindowId: string
) => {
  const {
    orderSetQuery: { data: orderSets },
  } = usePreOrderPrograms(orderWindowId);

  const orderSet = orderSets.find(
    (os) =>
      os.program?.id === programId &&
      os.orderCalendarMonth?.id === orderWindowId
  );
  return useOrderSetQuery(orderSet?.id, {
    initialData: orderSet,
  });
};

type CreateOrderSetPayload = {
  type: OrderSetType;
  orderWindowId?: string;
  programId?: string;
};

export const useCreateOrderSetMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { channel, territory } = useSelector((state: any) => state.app);
  return useMutation({
    mutationFn: ({
      orderWindowId,
      programId,
      ...data
    }: CreateOrderSetPayload) =>
      client
        .post<OrderSet>("order-sets", {
          __type: "order-set",
          channel,
          ...(territory
            ? {
                territory: {
                  type: "territory",
                  id: territory,
                },
                allRegions: false,
              }
            : { allRegions: true }),
          ...(programId && {
            program: {
              id: programId,
            },
          }),
          ...(orderWindowId && {
            orderCalendarMonth: {
              id: orderWindowId,
            },
          }),
          ...data,
          relationshipNames: ["program", "orderCalendarMonth", "territory"],
        })
        .then((res) => res.data),
    onSuccess: (orderSet) => {
      dispatch(setDraftOrderId({ orderType: orderSet.type, id: orderSet.id }));
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id).queryKey,
        orderSet
      );
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id)._ctx.orders.queryKey,
        []
      );
    },
  });
};

type CreateOrderSetVariantsPayload = {
  orderSetId?: string | null;
  variantIds: string[];
  orderType: OrderSetType;
  programId?: string;
};

export const useCreateOrderSetVariantsMutation = () => {
  const queryClient = useQueryClient();
  const createOrderSetMutaton = useCreateOrderSetMutation();

  return useMutation({
    mutationFn: async ({
      orderSetId,
      variantIds,
      orderType,
      programId,
    }: CreateOrderSetVariantsPayload) => {
      let osId = orderSetId;
      if (!osId) {
        const orderSet = await createOrderSetMutaton.mutateAsync({
          type: orderType,
          programId,
        });
        osId = orderSet.id;
      }
      const res = await asyncPool(5, variantIds, (variantId) =>
        client
          .post<OrderSetVariant>(`order-set-variants`, {
            __type: "order-set-variant",
            orderSet: {
              id: osId,
            },
            variant: {
              id: variantId,
            },
            relationshipNames: ["variant", "orderSet"],
          })
          .then((res) => res.data)
      );
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
      return { id: osId, orderSetVariants: res.results };
    },
    onSuccess: async ({ id, orderSetVariants }) => {
      // cancel requests for the same order set
      await queryClient.cancelQueries({
        queryKey: orderSetsKeyFactory.detail(id).queryKey,
      });
      return queryClient.setQueryData<OrderSet>(
        orderSetsKeyFactory.detail(id).queryKey,
        (os) =>
          os && {
            ...os,
            orderSetVariants: [...os.orderSetVariants, ...orderSetVariants],
          }
      );
    },
    onError: (_, { orderSetId }) => {
      if (!orderSetId) return;
      return queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
};

export const useApproveOrderSetsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (orderSetIds: string[]) => {
      const res = await asyncPool(5, orderSetIds, (orderSetId) =>
        client.post(
          `order-sets/${orderSetId}/approve`,
          {},
          { timeout: 10 * 60_000 }
        )
      );
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
  });
};

export const useDenyOrderSetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, note }: { id: string; note: string }) => {
      return client.post(
        `order-sets/${id}/deny`,
        { data: { note } },
        { serializeBody: false }
      );
    },
    onSuccess: (_, { id }) => {
      // invalidate inactive queries
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
        type: "inactive",
      });

      // modify active queries
      return queryClient.setQueriesData<Paginated<OrderSet>>(
        { queryKey: orderSetsKeyFactory.paginated._def, type: "active" },
        (oldData) =>
          oldData && {
            ...oldData,
            pages: oldData.pages.map((res) => ({
              ...res,
              data: res.data.filter((os) => os.id !== id),
            })),
          }
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
  });
};
