import globalReportReducer from "@features/reports/globalReportSlice";
import { combineReducers } from "@reduxjs/toolkit";

import addressReducer from "./slices/addresses/addressSlice";
import appReducer from "./slices/appSlice";
import c2mItemHistoryReducer from "./slices/c2mData/c2mItemHistorySlice";
import c2mOrderHistoryReducer from "./slices/c2mData/c2mOrderHistorySlice";
import itemApprovedOrDenied from "./slices/compliance/approveOrDenyItemSlice";
import complianceContactReducer from "./slices/compliance/complianceContactSlice";
import complianceItemsReducer from "./slices/compliance/complianceItemsSlice";
import complianceRulesReducer from "./slices/compliance/complianceRulesSlice";
import triggeredRulesReducer from "./slices/compliance/triggeredRulesSlice";
import errorReducer from "./slices/errorSlice";
import filterReducer from "./slices/filterSlice";
import globalLoadReducer from "./slices/globalLoadSlice";
import brandReducer from "./slices/items/brandSlice";
import businessUnitReducer from "./slices/items/businessUnitSlice";
import itemReducer from "./slices/items/itemSlice";
import orderCalendarMonthsReducer from "./slices/orderCalendarMonthsSlice";
import orderHistoryReducer from "./slices/ordering/orderHistorySlice";
import orderSetHistoryReducer from "./slices/ordering/orderSetHistorySlice";
import orderSetReducer from "./slices/ordering/orderSetSlice";
import preOrderDetailReducer from "./slices/ordering/preOrderDetailSlice";
import patchOrderReducer from "./slices/patchOrderSlice";
import changelogReducer from "./slices/planningTool/changelogSlice";
import itemProgramReducer from "./slices/planningTool/itemProgramSlice";
import noteReducer from "./slices/planningTool/noteSlice";
import programReducer from "./slices/planningTool/programSlice";
import rfqReducer from "./slices/planningTool/rfqSlice";
import programTypeReducer from "./slices/programs/programTypeSlice";
import programsReducer from "./slices/programs/programsSlice";
import purchaseOrderReducer from "./slices/purchasing/purchaseOrderSlice";
import rfpReducer from "./slices/purchasing/rfpSlice";
import reportReducer from "./slices/reports/reportSlice";
import supplierReducer from "./slices/suppliers/supplierSlice";
import territoryReducer from "./slices/territories/territorySlice";
import trackingReducer from "./slices/trackingSlice";
import userReducer from "./slices/users/userSlice";
import userUpdateReducer from "./slices/users/userUpdateSlice";
import variablePricingReducer from "./slices/variablePricing/variablePricingSlice";

const rootReducer = combineReducers({
  app: appReducer,
  addresses: addressReducer,
  brands: brandReducer,
  businessUnits: businessUnitReducer,
  changelog: changelogReducer,
  complianceContacts: complianceContactReducer,
  complianceItems: complianceItemsReducer,
  complianceRules: complianceRulesReducer,
  triggeredRules: triggeredRulesReducer,
  c2mItemHistory: c2mItemHistoryReducer,
  c2mOrderHistory: c2mOrderHistoryReducer,
  error: errorReducer,
  filters: filterReducer,
  globalLoad: globalLoadReducer,
  itemApprovedOrDenied: itemApprovedOrDenied,
  items: itemReducer,
  orderHistory: orderHistoryReducer,
  orderSet: orderSetReducer,
  orderSetHistory: orderSetHistoryReducer,
  patchOrder: patchOrderReducer,
  program: programReducer,
  preOrderDetails: preOrderDetailReducer,
  programs: programsReducer,
  programTypes: programTypeReducer,
  purchaseOrder: purchaseOrderReducer,
  reports: reportReducer,
  rfp: rfpReducer,
  suppliers: supplierReducer,
  territories: territoryReducer,
  tracking: trackingReducer,
  user: userReducer,
  userUpdate: userUpdateReducer,
  rfq: rfqReducer,
  variablePricing: variablePricingReducer,
  orderCalendarMonths: orderCalendarMonthsReducer,
  itemPrograms: itemProgramReducer,
  notes: noteReducer,
  globalReports: globalReportReducer,
});

export default rootReducer;
