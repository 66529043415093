/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListSubheader } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { isBefore, startOfDay } from "date-fns";

import { fetchAllOcms } from "../../../redux/slices/orderCalendarMonthsSlice";
import { formatDate } from "../../../utility/utilityFunctions";

const now = new Date();

const formatOrderWindowOptions = (ocms) => {
  const groupByType = ocms.reduce(
    (grp, ocm) => {
      grp[ocm.orderWindowType].push(ocm);
      return grp;
    },
    { national: [], secondary: [] }
  );

  return [
    ...groupByType.national,
    ...(groupByType.secondary.length > 0
      ? [{ label: "Secondary Order Windows", header: true }]
      : []),
    ...groupByType.secondary,
  ];
};
const formatOrderWindowOptionsPast = (ocms) => {
  const [past, upcoming] = ocms.reduce(
    (grp, ocm) => {
      grp[+!isBefore(startOfDay(formatDate(ocm.inMarketStartDate)), now)].push(
        ocm
      );
      return grp;
    },
    [[], []]
  );

  const groupByType = upcoming.reduce(
    (grp, ocm) => {
      grp[ocm.orderWindowType].push(ocm);
      return grp;
    },
    { national: [], secondary: [] }
  );

  return [
    { label: "Upcoming National", header: true },
    ...groupByType.national,
    ...(groupByType.secondary.length > 0
      ? [{ label: "Upcoming Secondary", header: true }]
      : []),
    ...groupByType.secondary,
    { label: "Past Order Windows", header: true },
    ...past,
  ];
};
const OrderWindowSelector = ({
  setOrderWindow,
  orderWindow,
  className,
  separatePast = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const { all: ocms } = useSelector((state) => state.orderCalendarMonths);
  const mapped = ocms.map((ocm) => ({ ...ocm, label: ocm.orderWindowName }));
  const ocmOptions = separatePast
    ? formatOrderWindowOptionsPast(mapped)
    : formatOrderWindowOptions(mapped);

  useEffect(() => {
    if (ocms.length === 0) dispatch(fetchAllOcms());
  }, [ocms]);

  return (
    <FormControl size="small" tw="w-min min-w-[215px]" className={className}>
      <InputLabel id="order-window-label">Order Window</InputLabel>
      <Select
        labelId="order-window-label"
        label="Order Window"
        id="order-window-selector"
        value={orderWindow || ""}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        onChange={(evt) => setOrderWindow(evt.target.value)}
        {...props}
      >
        {ocmOptions.map((ow, i) =>
          ow.header ? (
            <ListSubheader key={i}>{ow.label}</ListSubheader>
          ) : (
            <MenuItem key={ow.id} value={ow.id}>
              {ow.label}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default OrderWindowSelector;
