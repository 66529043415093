/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card, CircularProgress, Tab, Tabs } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import _ from "lodash";
import { utcDate } from "src/utility/utilityFunctions";

import { Contained } from "@components/StyledComponents";
import { ResourceError } from "@features/errors";
import { Filters, useFilterParams } from "@features/filters";
import usePreOrderPrograms from "@features/orders/preOrder/usePreOrderPrograms";
import {
  ProgramWithTargetOCM,
  getProgramBrandImageSrc,
  programsKeyFactory,
  useOCMProgramsQuery,
} from "@features/programs";
import { Button, FaIcon, PageTitle, Type } from "@features/ui";
import type { Program as TProgram } from "@models/Program";
import { CldImage } from "@services/cloudinary";
import useRoleIs from "@utils/useRoleIs";

const MULTI_BRAND = "Multi-Brand";

const FocusChip = tw.span`text-primary-500 text-sm bg-primary-50 rounded-full px-2 py-1`;

const Program = ({
  program,
  isOrderable,
}: {
  program: ProgramWithTargetOCM;
  isOrderable: boolean;
}) => {
  const queryClient = useQueryClient();
  const {
    orderSetQuery: { data, isLoading },
  } = usePreOrderPrograms(isOrderable ? program.targetOCM.id : undefined);
  const orderSet = data.find(
    (os) =>
      os.program?.id === program.id &&
      os.orderCalendarMonth?.id === program.targetOCM.id
  );

  return (
    <div tw="flex flex-col">
      <div tw="w-full">
        <Link
          to={`/programs/${program.id}/${program.targetOCM.id}`}
          tw="text-lg hover:underline"
          onClick={() =>
            queryClient.setQueryData<TProgram>(
              programsKeyFactory.detail(program.id).queryKey,
              program
            )
          }
        >
          {program.name}
        </Link>
        <p tw="text-neutral-500 text-sm">
          {program.brands.length > 1 &&
            _.sortBy(program.brands, "name")
              .map((b) => b.name)
              .join(", ")}
        </p>
      </div>
      {isOrderable && (
        <div tw="-ml-1">
          <Button
            size="small"
            color={
              !orderSet
                ? "secondary"
                : orderSet.isWorkComplete
                ? "success"
                : "primary"
            }
            endIcon={
              <FaIcon
                icon={orderSet?.isWorkComplete ? "check" : "arrow-right"}
              />
            }
            component={Link}
            to={`/orders/${program.targetOCM.id}/${program.id}`}
            state={{ createOrderSet: !isLoading && !orderSet }}
            loading={isLoading}
          >
            {!orderSet
              ? "Start Order"
              : orderSet.isWorkComplete
              ? "Completed"
              : "In Progress"}
          </Button>
        </div>
      )}
    </div>
  );
};

const BrandSection = ({
  brandName,
  children,
}: {
  brandName: string;
  children: React.ReactNode;
}) => (
  <Card
    component="section"
    tw="relative flex items-start gap-6 p-3 px-6 overflow-visible"
  >
    <div tw="w-24 flex-none sticky top-[var(--header-height)]">
      <div tw="rounded-full border-4 border-neutral-100 overflow-hidden">
        <CldImage
          src={getProgramBrandImageSrc({ brands: [{ name: brandName }] })}
          alt={brandName}
        />
      </div>
    </div>
    <div tw="w-full">
      <Type.H2 tw="mt-2 font-medium">{brandName}</Type.H2>
      <div tw="space-y-4">{children}</div>
    </div>
  </Card>
);

const PreOrderProgramList = ({
  orderWindowType,
  visibility,
}: {
  orderWindowType: "national" | "secondary";
  visibility: "active" | "visible";
}) => {
  const navigate = useNavigate();
  const [filters] = useFilterParams();
  const roleIs = useRoleIs();
  const {
    data: programs,
    isLoading,
    error,
  } = useOCMProgramsQuery(orderWindowType, visibility);

  let filteredPrograms = programs;

  if (filters.ocmId) {
    filteredPrograms = filteredPrograms.filter(
      (p) => p.targetOCM.id === filters.ocmId
    );
  }
  if (filters.preOrderProgramBrand) {
    filteredPrograms = filteredPrograms.filter((p) =>
      p.brands.some((b) => b.id === filters.preOrderProgramBrand)
    );
  }
  if (filters.businessUnits) {
    filteredPrograms = filteredPrograms.filter((p) =>
      p.brands.some((b) => filters.businessUnits.includes(b.businessUnit.name))
    );
  }

  const programsByBrand = _(filteredPrograms)
    .groupBy((p) => (p.brands.length === 1 ? p.brands[0].name : MULTI_BRAND))
    .entries()
    .sortBy(
      ([brand]) => (brand === "Multi-Brand" ? 1 : 0),
      ([brand]) => brand.toLowerCase()
    )
    .value();

  return (
    <Contained tw="space-y-6">
      <div tw="flex items-end gap-6">
        <PageTitle
          title={
            orderWindowType === "national"
              ? "National Pre-Orders"
              : "Special Order Window"
          }
        />
        <Tabs
          value={visibility}
          onChange={(_e, v) =>
            v === "visible"
              ? navigate(`/programs/${orderWindowType}/preview`)
              : navigate(`/programs/${orderWindowType}`)
          }
          tw="min-h-0"
        >
          <Tab
            label="Active Programs"
            value={"active"}
            tw="py-2 min-h-0 text-neutral-400 tracking-wider"
          />
          <Tab
            label="Preview Programs"
            value={"visible"}
            tw="py-2 min-h-0 text-neutral-400 tracking-wider"
          />
        </Tabs>
      </div>
      <Filters
        slots={["activeOcm", "preOrderProgramBrand", "businessUnits"]}
        alwaysShow={["preOrderProgramBrand", "businessUnits"]}
        hideSearch
        slotProps={{
          activeOcm: { orderWindowType, visibility },
          preOrderProgramBrand: { programs },
        }}
      />
      <div tw="space-y-6 pb-12">
        {error && <ResourceError error={error} />}
        {isLoading && <CircularProgress />}
        {!isLoading && !programsByBrand.length && (
          <Card tw="p-6 bg-neutral-100">
            <Type.H2 tw="text-neutral-500">
              No Programs match these criterias
            </Type.H2>
          </Card>
        )}
        {programsByBrand.map(([brand, programs]) => (
          <BrandSection key={brand} brandName={brand}>
            {_(programs)
              .groupBy("targetOCM.id")
              .entries()
              .sortBy("1.targetOCM.inMarketStartDate")
              .value()
              .map(([_ocmId, programs]) => {
                const ocm = programs[0].targetOCM;
                return (
                  <div key={programs[0].id}>
                    <div tw="text-neutral-500 mt-1 flex gap-x-3 gap-y-1 flex-wrap">
                      <span>
                        Focus Month:{" "}
                        <FocusChip>
                          {format(utcDate(ocm.inMarketStartDate), "MMMM yyyy")}
                        </FocusChip>
                      </span>
                      <span>
                        Order Window:{" "}
                        <FocusChip>
                          {format(utcDate(ocm.orderWindowOpenDate), "PP")} -{" "}
                          {format(utcDate(ocm.orderWindowCloseDate), "PP")}
                        </FocusChip>
                      </span>
                      {!roleIs("field1") && (
                        <div tw="xl:ml-auto">
                          Forward Deployed Close:{" "}
                          <FocusChip>
                            {format(utcDate(ocm.purchasingCloseDate), "MMM d")}
                          </FocusChip>
                        </div>
                      )}
                    </div>
                    <div tw="max-w-screen-md grid grid-cols-2 gap-4 mt-4">
                      {_.sortBy(programs, (p) => p.name.toLowerCase()).map(
                        (program) => (
                          <Program
                            key={program.id}
                            program={program}
                            isOrderable={visibility === "active"}
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              })}
          </BrandSection>
        ))}
      </div>
    </Contained>
  );
};

export default PreOrderProgramList;
